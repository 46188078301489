import IntelliProveMediaError, { NoCameraMediaError } from "../exceptions/intelliprove_media_exception";
import StreamResolution from "../models/stream_resolution";
import { SDK_CONFIG } from "../statics";
import { MEDIA_CONFIG } from "../statics";
import { wait } from "../utils/async";
import VideoStreamValidation from "./video_stream_validation";

export type MediaServiceErrorCallback = (err: IntelliProveMediaError) => void;

export class DrawBounds {
  srcX: number = 0;
  srcY: number = 0;
  srcW: number = 0;
  srcH: number = 0;

  dstX: number = 0;
  dstY: number = 0;
  dstW: number = 0;
  dstH: number = 0;
}

export default class MediaService {
  videoEl: HTMLVideoElement;

  fps: number = MEDIA_CONFIG.targetFps;
  frameSize: number = MEDIA_CONFIG.frameSize;
  imageType: string = MEDIA_CONFIG.frameType;
  imageQuality: number = MEDIA_CONFIG.frameQuality;
  videoLoadTimeout: number = MEDIA_CONFIG.videoElementLoadTimeout;
  resolution: StreamResolution = new StreamResolution(MEDIA_CONFIG.cameraWidth, MEDIA_CONFIG.cameraHeight);

  debugMode: boolean = false;

  _videoStream: MediaStream | null = null;
  _errorCallback: MediaServiceErrorCallback;
  _recording: boolean = false;
  _offscreenCanvas: HTMLCanvasElement | null = null;
  _until: number | null = null;
  _runningFps: number | null = null;
  _nframes: number = 0;
  _syOffset: number = 0.5;

  constructor(videoElement: HTMLVideoElement, recordingErrorCallback: MediaServiceErrorCallback) {
    this.videoEl = videoElement;
    this._errorCallback = recordingErrorCallback;
    if (this._isPortrait()) {
      this.frameSize = MEDIA_CONFIG.frameSizePortrait;
    }
  }

  public actualResolution(): StreamResolution {
	if (!this._videoStream) 
	  return undefined;
    let track = VideoStreamValidation.getTrackFromStream(this._videoStream);
    return VideoStreamValidation.getActualStreamResolution(track);
  }

  public maxSupportedFps(): number {
	if (!this._videoStream) 
	  return undefined;
    let track = VideoStreamValidation.getTrackFromStream(this._videoStream);
    return VideoStreamValidation.getMaxSupportedFramerate(track);
  }

  private _isPortrait(): boolean {
    const videoAspectRatio = this.videoEl.videoWidth / this.videoEl.videoHeight;
    const frameAspectRatio = this.frameSize / this.frameSize;
    return videoAspectRatio < frameAspectRatio;
  }

  private _initOffscreenCanvas(): void {
    if (this._offscreenCanvas !== null) {
      return;
    }

    const canvas = document.createElement("canvas");
    document.body.appendChild(canvas);

    canvas.width = this.frameSize;
    canvas.height = this.frameSize;
    canvas.style.position = "fixed";
    canvas.style.left = "200vw";

    this._offscreenCanvas = canvas;
  }

  private _removeOffscreenCanvas(): void {
    if (this._offscreenCanvas === null) {
      return;
    }

    this._offscreenCanvas.remove();
  }

  private _getDrawBounds(): DrawBounds {
    const bounds = new DrawBounds();

    if (this._isPortrait()) {
      const heightWidthRatio = 1.45; // height == widht * heightWidthRatio
      const wantedHeight = this.videoEl.videoWidth * heightWidthRatio;

      bounds.srcY = Math.floor((this.videoEl.videoHeight - wantedHeight) / 2);
      bounds.srcW = this.videoEl.videoWidth;
      bounds.srcH = wantedHeight;

      bounds.dstH = this.frameSize;
      bounds.dstW = this.frameSize / heightWidthRatio;
    } else {
      bounds.srcX = Math.floor((this.resolution.width - this.resolution.height) / 2);
      bounds.srcY = 0;
      bounds.srcW = this.resolution.height;
      bounds.srcH = this.resolution.height;

      bounds.dstH = this.frameSize;
      bounds.dstW = this.frameSize;
    }
    return bounds;
  }

  private _getCroppedFrame(): string {
    const bounds = this._getDrawBounds();

    const canvas = this._offscreenCanvas;
    canvas.width = bounds.dstW;
    canvas.height = bounds.dstH;

    const ctx = canvas.getContext("2d");
    ctx.drawImage(this.videoEl, bounds.srcX, bounds.srcY, bounds.srcW, bounds.srcH, bounds.dstX, bounds.dstY, bounds.dstW, bounds.dstH);

    return canvas.toDataURL(this.imageType, this.imageQuality);
  }

  private _registerFrameFps(frameInterval: number) {
    this._nframes += 1;
    this._runningFps = this._runningFps + (1000 / frameInterval - this._runningFps) / this._nframes;
  }

  async awaitVideoLoading(): Promise<void> {
    for (let i = 0; i < this.videoLoadTimeout / 100; i++) {
      if (this.videoEl.readyState === 4) {
        await wait(200); // Wait another 200ms to avoid camera glitch
        return;
      }
      await wait(100); // wait 100ms
    }

    if (this.videoEl.readyState !== 4) {
      console.error("Video element is not loading...");
      throw new IntelliProveMediaError(`Failed to fetch a frame from video element as it does not have the ready state! Actual video element: ${this.videoEl.readyState} !== 4`);
    }
  }

  async dataUrlToBlob(dataUrl: string): Promise<Blob> {
    return await (await fetch(dataUrl)).blob();
  }

  async downloadSnapshot(dataUrl: string) {
    const link = document.createElement("a");
    link.download = "snapshot.png";
    link.href = dataUrl;
    link.click();
    link.remove();
  }

  async qualityCheckImage(): Promise<Blob> {
    await this.awaitVideoLoading();
    this._initOffscreenCanvas();
    const dataUrl = this._getCroppedFrame();
    if (this.debugMode) {
      this.downloadSnapshot(dataUrl);
    }

    return await this.dataUrlToBlob(dataUrl);
  }

  async openCameraStream(deviceId: string | null = null) {
    if (deviceId === null && this._videoStream !== null) {
      return;
    }

    this.detach();

    try {
      const constraints =
        deviceId === null
          ? {
              audio: false,
              video: {
                width: this.resolution.width,
                height: this.resolution.height,
                facingMode: "user",
              },
            }
          : {
              audio: false,
              video: {
                width: this.resolution.width,
                height: this.resolution.height,
                deviceId: deviceId,
              },
            };

      if (this.debugMode) {
        console.info("Found cameras!");
      }
      this._videoStream = await navigator.mediaDevices.getUserMedia(constraints);
      this.videoEl.srcObject = this._videoStream;
      if (this.debugMode) {
        console.info("Set stream!");
      }
    } catch (error) {
      if (this.debugMode) {
        console.warn("No camera found!");
        console.error(error);
      }
      throw new NoCameraMediaError();
    }

    VideoStreamValidation.validate(this._videoStream, this._isPortrait());
  }

  record(duration: number, frameCallback: CallableFunction, stopCallback: CallableFunction) {
    if (this._recording) {
      return;
    }

    this._runningFps = null;
    this._nframes = 0;
    this._recording = true;
    this._initOffscreenCanvas();

    let previous: number;
    const targetFrameInterval = 1000 / this.fps;
    const forgiveness = 4; // in ms

    this._until = window.performance.now() + duration;

    const recordFrame = () => {
      if (!this._recording) {
        stopCallback(false);
        this._removeOffscreenCanvas();
        return;
      }

      // Higher percision than Date.now()
      const t = window.performance.now();
      previous = previous === undefined ? t : previous;

      if (t >= this._until) {
        this._recording = false;
        stopCallback(true);
        this._removeOffscreenCanvas();
        return;
      }

      requestAnimationFrame(recordFrame);

      const frameInterval = t - previous;
      if (frameInterval < targetFrameInterval - forgiveness) {
        return;
      }
      this._registerFrameFps(frameInterval);
      previous = t;

      const frame = this._getCroppedFrame();
      frameCallback(frame, t);

      if (this._runningFps < SDK_CONFIG.minFps && this._nframes > this.fps * 3) {
        const err = new IntelliProveMediaError(
          "Recording fps is too low to continue streaming! Current fps: " + this._runningFps.toString(),
          false,
        );
        this._errorCallback(err);
        this._recording = false;
        return;
      }
    };

    requestAnimationFrame(recordFrame);
  }

  stop() {
    this._recording = false;
  }

  restart(duration: number) {
    this._until = window.performance.now() + duration;
  }

  detach() {
    if (this._videoStream === null) {
      return;
    }

    this._videoStream.getTracks().forEach((track) => {
      track.stop();
    });
  }
}
